import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
const SignupPage = () => {

	//TODO:: Redirect user to myaccount page if they are already logged in
	return (
		<main>
			<Header/>
				<div className="container pt-60 mb-30">
					<div className="row justify-content-center">
						<div className="col-xs-12 col-md-4  ">
                             <h1 className="text-center mb-1">Thank you!</h1>
                             <h3 className="text-center">User created successfully</h3>
                        </div>
					</div>
				</div>
			<Footer/>
		</main>
	)
}

export default SignupPage